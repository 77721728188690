import React from 'react'
import {renderImperatively} from '../utils/renderImperatively'
import {Dialog} from "@mui/material";
import {DialogProps} from "@mui/material/Dialog/Dialog";

export type DialogShowProps = Omit<DialogProps, 'open'> & {
    open?: boolean
};

export type DialogShowHandler = {
    close: () => void
}

export const closeFnSet = new Set<() => void>()

export function show(props: DialogShowProps) {
    const handler: DialogShowHandler = renderImperatively(
        // @ts-ignore
        <Dialog
            {...props}
            onClose={(...args) => {
                closeFnSet.delete(handler.close)
                props.onClose?.apply(null, args)
            }}
        >
            {props.children}
        </Dialog>
    )
    closeFnSet.add(handler.close)
    return handler
}